.App {
  text-align: center;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #ffffff;
}

canvas {
  background-color: black;
  width: 100%;
  height: 100%;
  opacity: 0.8;
}

.container {
  background: rgb(0 0 0 / 80%);
  opacity: 1;
  padding: 32px 24px;
  width: 412px;
  max-width: calc(100vw - 24px);
  max-height: calc(100vh - 64px);
  position: absolute;
  border-radius: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
}

.logo {
  width: 280px;
  max-width: calc(100% - 52px);
}

.send-button {
  padding: 20px 16px;
  border-radius: 8px;
  background-color: #ff3331;
  cursor: pointer;
  color: #ffffff;
  font-size: 16px;
  outline: none;
  width: 100%;
  border: none;
}

h2 {
  margin: 28px 0;
}

.error {
  margin-top: 6px;
  padding-left: 12px;
  color: #ff3331;
  font-size: 12px;
  width: 100%;
  text-align: left;
}

.logo-section {
  display: flex;
  align-items: center;
}
