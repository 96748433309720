body {
  margin: 0;
  font-family: 'mabry-pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: black;
}

* {
  box-sizing: border-box;
  font-family: 'mabry-pro', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@font-face {
  font-family: 'mabry-pro';
  font-style: normal;
  font-weight: normal;
  src: url('./fonts/mabry-pro.otf') format('opentype');
}

@font-face {
  font-family: 'mabry-pro';
  font-style: italic;
  font-weight: normal;
  src: url('./fonts/mabry-pro-italic.otf') format('opentype');
}

@font-face {
  font-family: 'mabry-pro';
  font-style: italic;
  font-weight: bold;
  src: url('./fonts/mabry-pro-bold-italic.otf') format('opentype');
}

@font-face {
  font-family: 'mabry-pro';
  font-style: normal;
  font-weight: bold;
  src: url('./fonts/mabry-pro-bold.otf') format('opentype');
}
